import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, TaxonomyRouteMatch} from "../../../routing/routes";
import {getAppEnvironment} from "../../../routing/routing.utils";
import {environment} from "../../../env";
import {SelectDataset} from "../../../components/main/header/SelectDataset";
import React from "react";
import {MainMenuSynergyComponent} from "./MainMenuSynergy";
import {MultiDropdownMenu} from "./MultiDropdownMenu";
import {MainMenuSingleComponent} from "./MainMenuSingle";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {SHOW_TEST_MENU} from "../../default-menus/SingleHeader";

export const MultiHeader = observer(() => {
    const {rootAppStore} = useStores();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const appEnv = getAppEnvironment(m.path);
    // console.debug('Re-rendering MultiHeader', appEnv)
    return <>
        {SHOW_TEST_MENU && environment.isTest &&
            <div style={{background: 'olivedrab'}}>
                MultiHeader ({'' + appEnv}-{'' + rootAppStore.app.dashType})
            </div>}
        {(appEnv === 'synergy_v3' || appEnv === 'synergy_merge') && <MainMenuSynergyComponent/>}
        {(appEnv === 'single_cleansing' || appEnv === 'single_analytics') && <MainMenuSingleComponent/>}
        <div style={{flexGrow: 1}}/>
        <SelectDataset/>
        <MultiDropdownMenu/>
    </>;
})
