import React from "react";
import {observer} from "mobx-react-lite";
import './DashBoard.scss'
import {useStores} from "../../stores";
import {Alert, AlertTitle, Button, Grid} from "@mui/material";
import {DashBoardCard} from "./DashBoardCard";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {Bag} from "../../services/classes/Bag";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {useRouteMatch} from "react-router-dom";
import {SupplierSegmentationIcon} from "../../components/icons/SupplierSegmentationIcon";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";

export const VionDashBoard: React.FC = observer(() => {
    const {bagStore, categorizationStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const bag = bagStore.bag as Bag;
    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;
    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    return <>
        {
            newDatasetId && bagStore.allBags !== undefined && <div className="dashboard-grid-alert">
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Alert variant="standard" severity="warning" action={
                            <Button
                                color="inherit" size="small"
                                onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                                Open the new dataset
                            </Button>
                        }>
                            <AlertTitle>This is an old version</AlertTitle>
                            This dataset is reviewed and all the changes are applied in a newer version of the dataset
                        </Alert>
                    </Grid>
                </Grid>
            </div>
        }
        <Grid container
              className="dashboard dashboard-v2-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.job_koi_kdf, {id: bag.id})}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.job_ai_cat_welcome, {id: bag.id})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    link={generatePath(routes.job_taxonomy_builder, {id: bag.id})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common ?
                    <CurrencyComponent v={bagStore.kpi.common.total_spend}
                                       nDigits={3}/> : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    // value={topXSpend ? `${Math.round(topXSpend)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    // valueColor={topXSpend ? (topXSpend < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.job_koi_sc, {id: bag.id})}
                />
            </DashboardRow>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.job_koi_ss, {id: bag.id})}
                />
            </DashboardRow>
        </Grid>
    </>
});

