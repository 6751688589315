import './LookerPreviewPage.scss';
import React from "react";
import {environment} from "../../env";

export const LookerPreviewPage: React.FC = () => {
    return <iframe
        title="looker-preview-page"
        className="looker-preview-page"
        src={environment.lookerPreviewUrl}
        width="1860px"
        height="820px"
    />
};
