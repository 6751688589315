import React from "react";
import {SelectDataset} from "../../components/main/header/SelectDataset";
import {SingleDropdownMenu} from "./SingleDropdownMenu";
import {useStores} from "../../stores";
import {FullMainMenu} from "./main-menus/FullMainMenu";
import {getAppEnvironment} from "../../routing/routing.utils";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, TaxonomyRouteMatch} from "../../routing/routes";
import {SynergyV1Menu} from "./main-menus/SynergyV1Menu";
import {TaxonomyMergeMenu} from "./main-menus/TaxonomyMergeMenu";
import {DefaultMenu} from "./main-menus/DefaultMenu";
import {environment} from "../../env";

export const SHOW_TEST_MENU = true;

export const SingleHeader = () => {
    const {p} = useStores();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const appEnv = getAppEnvironment(m.path);

    let menu: JSX.Element;
    let debug: string
    if (p.p.hide_main_menu) {
        menu = <></>;
        debug = 'hidden';
    } else {
        if (p.p.fullMenu) {
            menu = <FullMainMenu/>;
            debug = 'fullMenu';
        } else {
            debug = appEnv;
            switch (appEnv) {
                case 'synergy_v1':
                    menu = <SynergyV1Menu/>
                    break;
                case 'taxonomy_merge':
                    menu = <TaxonomyMergeMenu/>
                    break;
                case 'default':
                case 'pic':
                    menu = <DefaultMenu/>;
                    break;
                default:
                    menu = <></>;
                    debug = 'rest';
                    break;
            }
        }
    }

    return <>
        {SHOW_TEST_MENU && environment.isTest &&
            <div style={{background: 'olivedrab'}}>{debug} ({appEnv})</div>
        }
        {menu}
        <div style={{flexGrow: 1}}/>
        <SelectDataset/>
        <SingleDropdownMenu/>
    </>;
}
