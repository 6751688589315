import React from "react";
import {observer} from "mobx-react-lite";
import './MultiDashBoards.scss'
import {useStores} from "../../stores";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {Bag} from "../../services/classes/Bag";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {DashBoardCard} from "../dashboard/DashBoardCard";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {DashboardGridAlert} from "./DashboardGridAlert";
import {TaxonomyMapperIcon} from "../../components/icons/TaxonomyMapperIcon";

export const SynergyMergeDashboard: React.FC = observer(() => {
    const {bagStore, categorizationStore, taxonomyManagerStore, taxonomyMapperStore} = useStores();
    const bag = bagStore.bag as Bag;
    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    return <>
        <DashboardGridAlert/>
        <Grid container
              className="dashboard synergy-analytics-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Viewer"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.synergy_merge_taxonomy_viewer, {id: bag.id})}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    value={taxonomyMapperStore.mapper.unMatchedCategories?.length
                        || DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
                    subtitle="unmapped categories"
                    link={generatePath(routes.synergy_merge_taxonomy_mapper, {id: bag.id})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Harmonize Categories"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.single_cleansing_cat_review, {id: bag.id})}
                    badge="Ai"
                />
            </DashboardRow>
        </Grid>
    </>
});
