import {GROUP_SUPER, GROUP_USER} from "./services/ApiTypes";
import {User} from "./stores/AuthStore";

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST
const MATERIALIZED_HOST = process.env.REACT_APP_MATERIALIZED_HOST
const staticFilesUrl =
    (process.env.REACT_APP_DJANGO_GCP_STATIC_BUCKET ? `https://storage.googleapis.com/${process.env.REACT_APP_DJANGO_GCP_STATIC_BUCKET}/static` : '')
    || 'http://localhost:8500/static';
const environmentName = process.env.REACT_APP_ENVIRONMENT || '?';
const customerName = process.env.REACT_APP_CUSTOMER || '?';
const IS_DEV = Boolean(process.env.REACT_APP_DEV);
// const IS_DEV = true;
const baseUrl = process.env.REACT_APP_URL || 'http://localhost:3008';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const staticIo = {
    inFilename: '20210409 Spend Analysis.xlsx',
    outFilename: '20210409 Spend Analysis_result.xlsx',
    outFileUrl: 'https://storage.googleapis.com/mithra-cat-production-static/demo/20210409%20Spend%20Analysis_result.xlsx',
}

const demoFiles = {
    spendClassificationResult: {
        name: 'LibertyGlobal-Categorization.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-Categorization.xlsx',
    },
    supplierHierarchy: {
        name: 'LibertyGlobal-SupplierHierarchy.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-SupplierHierarchy.xlsx',
    }
}

export const DEV_USER_DUMMY: User = {
    id: 2,
    username: 'dummy',
    email: 'dummy@mithra-ai.com',
    first_name: 'Demo',
    last_name: 'Vion',
    groups: [GROUP_USER, GROUP_SUPER],
    // groups: [GROUP_USER],
    is_staff: false,
    mithrauserdata: {
        is_edit_allowed: true,
        is_upload_allowed: true,
    }
};

let useDevMenu = false;
/**
 * Disable or enable the dev menu here
 */
// useDevMenu = true;

export const environment = {
    environmentName,
    customerName, // Warning: Do not add customer name itself to deployed applications
    locale: undefined as (string | undefined), // https://momentjs.com/ (en-GB, en-US, nl-NL)
    production: !IS_DEV,
    testAuthentication: environmentName.endsWith('auth'),
    useDeprecatedGoogleAuthentication: Boolean(process.env.REACT_APP_BYPASS_GOOGLE_AUTHENTICATION),
    isTest: false,
    devMenu: IS_DEV && useDevMenu,
    devLogin: IS_DEV, // Automatically login with DEV_USER_ID
    // devLogin: false,
    authUrl: `${MATERIALIZED_HOST}/auth`, // Note: Please update to the correct value for deployment of vion
    apiUrl: `${BACKEND_HOST}/api`,
    adminUrl: `${BACKEND_HOST}/admin`,
    materializedApiUrl: `${MATERIALIZED_HOST}/api`,
    staticFilesUrl,
    apiDelay: IS_DEV ? 600 : 0,
    baseUrl,
    googleClientId,
    staticIo,
    lookerPreviewUrl: `${process.env.REACT_APP_LOOKER_PREVIEW_URL}`,
    demoFiles,
    aiVmName: 'ai-workhorse-6-eu-west4-a-1',
    fakePPVData: false,
    jobOrderingNewToOld: false,
    categorizationReviewPageSize: 15,
    categorizationFeedbackPageSize: 100,
    aiResultFakeTimeout: 2500,
};

//region Dennis Dev
if (IS_DEV) {
    //
    environment.apiDelay = 0;
    environment.isTest = true;
    //
}
//endregion

export const HACKS = [
    // 'sankey_tax_switch',
];

export const TIMINGS = {
    'input_loading': [
        1, // Uploading
        15, // Parsing Excel
        7, // Optimizing Excel
        3, // Merging File(s)
    ], // seconds
}

export const DISABLE_UPLOAD_DATA = false
export const DISABLE_FRANKE_DASHBOARD_BUTTONS = true
export const DISABLE_FRANKE_MENU = false

// Removed during development of PPV module
// export function isPpvMissingData(bag: Bag) {
//     switch (getCustomer(bag)) {
//         case 'franke': if (bag.name.toLowerCase().includes('indirect')) return true
//     } return false
// }

if (!MATERIALIZED_HOST) {
    if (environment.production) {
        console.error('Misconfiguration error: Missing MATERIALIZED_HOST variable')
    } else {
        throw new Error('Misconfiguration error: Missing MATERIALIZED_HOST variable')
    }
}
