import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {generatePath, useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {Bag} from "../../services/classes/Bag";
import {Alert, AlertTitle, Button, Grid} from "@mui/material";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DashBoardCard} from "./DashBoardCard";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {routes} from "../../routing/routes";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {MoveIcon} from "../../components/icons/MoveIcon";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {SupplierSegmentationIcon} from "../../components/icons/SupplierSegmentationIcon";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {QueryStats} from "@mui/icons-material";
import {TaxonomyMapperIcon} from "../../components/icons/TaxonomyMapperIcon";


export const PicDashBoard: React.FC = observer(() => {
    const {
        bagStore,
        categorizationStore,
        spendConcentrationStore,
        ppvControllerStore,
        taxonomyMapperStore
    } = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const bag = bagStore.bag as Bag;

    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;
    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const scTop = spendConcentrationStore.topValueP;
    const ppvTotal = ppvControllerStore.spendTotalPPV;

    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;

    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;
    return <>
        {
            newDatasetId && bagStore.allBags !== undefined && <div className="dashboard-grid-alert">
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Alert variant="standard" severity="warning" action={
                            <Button
                                color="inherit" size="small"
                                onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                                Open the new dataset
                            </Button>
                        }>
                            <AlertTitle>This is an old version</AlertTitle>
                            This dataset is reviewed and all the changes are applied in a newer version of the dataset
                        </Alert>
                    </Grid>
                </Grid>
            </div>
        }
        <Grid container
              className="dashboard dashboard-v2-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={nCategories}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    // value={nCategories}
                    // subtitle="categories"
                    link={generatePath(routes.job_koi_kdf, {id: bag.id})}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.job_ai_cat_welcome, {id: bag.id})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    link={generatePath(routes.job_taxonomy_builder, {id: bag.id})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common ?
                    <CurrencyComponent v={bagStore.kpi.common.total_spend}
                                       nDigits={3}/> : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(ppvTotal)}
                    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.job_koi_ppv, {id: bag.id})}
                />
                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Advanced Dashboard"
                    // value={drawOpportunityValue(ppvTotal)}
                    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="Preview"
                    link={generatePath(routes.looker_preview_id, {id: bag.id})}
                    // badge="preview"
                />
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    value={unmatchedCategories === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}
                    subtitle="unmapped categories"
                    link={generatePath(routes.pic_common_categories, {id: bag.id})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.job_koi_sc, {id: bag.id})}
                />
                {/*<DashBoardCard*/}
                {/*    avatar={<PersonHierarchyIcon/>}*/}
                {/*    title="Improve Supplier Hierarchy"*/}
                {/*    link={generatePath(routes.job_koi_sh, {id: bag.id})}*/}
                {/*    badge="Ai"*/}
                {/*    isTrial={DISABLE_FRANKE_DASHBOARD_BUTTONS}*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.job_koi_ss, {id: bag.id})}
                />
            </DashboardRow>
        </Grid>
    </>
});
