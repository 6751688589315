import {Route} from 'react-router-dom';
import {AuthRoute, RouteSpec} from "./AuthRoute";
import React from "react";
import {NotFound} from "./NotFound";
import {LoginPage} from "../auth/LoginPage";
import {SimpleEnvWrapper} from "../jobs/wrapper/SimpleEnvWrapper";
import {SpendConcentration} from "../jobs/koi/spend-concentration/SpendConcentration";
import {KOIPurchasePriceVariance} from "../jobs/koi/purchase-price-variance/KOIPurchasePriceVariance";
import {MainComponent} from "../components/main/MainComponent";
import {WrappedRoute} from "./WrappedRoute";
import {KoiKeyDataFacts} from "../jobs/koi/key-data-facts/KoiKeyDataFacts";
import {KoiSupplierHierarchy} from "../jobs/koi/supplier-hierarchy/KoiSupplierHierarchy";
import {ManageDataPage} from "../pages/manage-data/ManageDataPage";
import {KOICashFlowOptimization} from "../jobs/koi/koi-cash-flow-optimization/KOICashFlowOptimization";
import {KOISupplierSegmentation} from "../jobs/koi/koi-supplier-segmentation/KOISupplierSegmentation";
import {SynergySelectionPage} from "../pages/synergy_v1/selection/SynergySelectionPage";
import {SynergyDashboardPage} from "../pages/synergy_v1/dashboard/SynergyDashboardPage";
import {routes} from './routes';
import {SynergySuppliersPage} from "../pages/synergy_v1/suppliers/SynergySuppliersPage";
import {SynergyCategoriesPage} from "../pages/synergy_v1/categories/SynergyCategoriesPage";
import {DoubleEnvWrapper} from "../jobs/wrapper/DoubleEnvWrapper";
import {OpportunitiesTrello} from "../pages/opportunities-trello/OpportunitiesTrello";
import {DashBoard} from '../pages/dashboard/Dashboard';
import {TaxonomyEditorPage} from "../pages/taxonomy-editor/TaxonomyEditorPage";
import {ApprovalListPage} from "../pages/approval/ApprovalListPage";
import {ApprovalCategorizationPage} from "../pages/approval/categorization/ApprovalCategorizationPage";
import {ApprovalTaxonomyPage} from "../pages/approval/taxonomy/ApprovalTaxonomyPage";
import {TaxonomyMapperPage} from "../pages/taxonomy-mapper/TaxonomyMapperPage";
import {TaxonomyMapperReviewPage} from "../pages/taxonomy-mapper-review/TaxonomyMapperReviewPage";
import {AtkDashBoard} from "../pages/atk-dashboard/AtkDashboard";
import {AiCategorizationPage} from "../pages/ai-categorization/AiCategorizationPage";
import {TaxonomyEditorViewOnlyPage} from "../pages/taxonomy-editor/TaxonomyEditorViewOnlyPage";
import {ReportingPage} from "../pages/reporting/ReportingPage";
import {CategorizationPage} from '../pages/categorization/CategorizationPage';
import {SingleCleaningDashboard} from "../pages/multi-dashboard/SingleCleaningDashboard";
import {SingleAnalyticsDashboard} from "../pages/multi-dashboard/SingleAnalyticsDashboard";
import {SynergyAnalyticsDashboard} from "../pages/multi-dashboard/SynergyAnalyticsDashboard";
import {SynergyMergeDashboard} from "../pages/multi-dashboard/SynergyMergeDashboard";
import {MultiEnvWrapper} from "../jobs/wrapper/MultiEnvWrapper";
import {ManageMasterDataPage} from "../pages/multi-dashboard/data/ManageMasterDataPage";
import {SupplierNormalizationPage} from "../pages/supplier-normalization/SupplierNormalizationPage";
import {NestleEnvWrapper} from "../jobs/wrapper/NestleEnvWrapper";
import {NestleDashboard} from "../pages/multi-dashboard/nestle-dashboard/NestleDashboard";
import {SupplierComparePage} from "../pages/supplier-normalization/SupplierComparePage";
import {LookerPreviewPage} from "../pages/looker-preview/LookerPreviewPage";
import {PicDashBoard} from "../pages/dashboard/PicDashboard";

// The different variants of wrappers
const noD: RouteSpec = [SimpleEnvWrapper, {fluid: true, dashEnv: 'simple', dataType: 'no_data'}] // No data wrapper
const sim: RouteSpec = [SimpleEnvWrapper, {fluid: true, dashEnv: 'simple'}]
const duo: RouteSpec = [DoubleEnvWrapper, {fluid: true, dashEnv: 'dual'}]
const ms1: RouteSpec = [MultiEnvWrapper, {fluid: true, dashEnv: 'multi', dashType: 'single', dataType: 'default'}]
const msy: RouteSpec = [MultiEnvWrapper, {fluid: true, dashEnv: 'multi', dashType: 'synergy', dataType: 'default'}]
const msN: RouteSpec = [NestleEnvWrapper, {fluid: true, dashEnv: 'nestle'}]

// Shortcuts to save space
const r = routes;
const R = AuthRoute;
let _k = 0;

function k() {
    return _k++;
}

// Matches the routes (top to bottom)
export const allRoutes = [
    // New user flow
    <R key={k()} w={noD} path={r.home} component={ManageDataPage} notStrict exact/>,

    <R key={k()} w={msN} path={r.nestle_dashboard} component={NestleDashboard} exact/>,
    <R key={k()} w={msN} path={r.nestle_supplier_master_normalization} component={SupplierNormalizationPage}/>,
    <R key={k()} w={msN} path={r.nestle_categorization_review} component={AiCategorizationPage}/>,
    <R key={k()} w={msN} path={r.nestle_categorization_review_result} component={AiCategorizationPage}/>,
    <R key={k()} w={msN} path={r.nestle_supplier_normalization} component={SupplierNormalizationPage}/>,
    <R key={k()} w={msN} path={r.nestle_common_suppliers} component={SupplierComparePage}/>,
    <R key={k()} w={msN} path={r.nestle_common_categories_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={msN} path={r.nestle_common_categories} component={TaxonomyMapperPage}/>,
    <R key={k()} w={msN} path={r.nestle_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={msN} path={r.nestle_taxonomy_builder} component={TaxonomyEditorPage}/>,

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Multi Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // single_cleansing
    <R key={k()} w={ms1} path={r.single_cleansing_dashboard} component={SingleCleaningDashboard}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_sup_nor} component={SupplierNormalizationPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_cat_welcome} component={CategorizationPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_cat_busy} component={CategorizationPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_cat_review} component={CategorizationPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_cat_result} component={CategorizationPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_taxonomy_builder} component={TaxonomyEditorPage}/>,
    <R key={k()} w={ms1} path={r.single_cleansing_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={ms1} path={r.single_data_manage} component={ManageDataPage}/>,
    <R key={k()} w={noD} path={r.single_data_home2} component={ManageDataPage}/>,
    <R key={k()} w={noD} path={r.single_data_home} component={ManageDataPage}/>,
    // single_analytics_dashboard
    <R key={k()} w={ms1} path={r.single_analytics_dashboard} component={SingleAnalyticsDashboard}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_ss} component={KOISupplierSegmentation}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_kdf} component={KoiKeyDataFacts}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_ppv} component={KOIPurchasePriceVariance}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_sc} component={SpendConcentration}/>,
    // KOICashFlowOptimization
    // KoiSupplierHierarchy
    //
    // single dashboard rest
    <R key={k()} w={msy} path={r.single_data_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.single_data_opportunities_id} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.single_data_report} component={ReportingPage}/>,
    // synergy_v3_dashboard
    <R key={k()} w={msy} path={r.synergy_v3_suppliers} component={SynergySuppliersPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_categories} component={SynergyCategoriesPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_spend} component={SynergyDashboardPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_data} component={ManageMasterDataPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_dashboard} component={SynergyAnalyticsDashboard}/>,
    // synergy_merge_dashboard
    <R key={k()} w={msy} path={r.synergy_merge_review} component={AiCategorizationPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_review_result} component={AiCategorizationPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_builder} component={TaxonomyEditorPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_mapper_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_mapper} component={TaxonomyMapperPage}/>,
    // synergy_v3 rest
    <R key={k()} w={msy} path={r.synergy_v3_opportunities_id} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.synergy_v3_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.synergy_v3_report} component={ReportingPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_dashboard} component={SynergyMergeDashboard}/>,
    // Must be at the end
    <R key={k()} w={msy} path={r.synergy_v3} component={ManageDataPage}/>,
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // PIC Dashboard
    <R key={k()} w={sim} path={r.pic_dashboard} component={PicDashBoard} exact/>,
    <R key={k()} w={sim} path={r.pic_common_categories_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={sim} path={r.pic_common_categories} component={TaxonomyMapperPage}/>,

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // App logic
    <R key={k()} w={sim} path={r.job_dashboard} component={DashBoard}/>,
    <R key={k()} w={sim} path={r.job_koi_sc} component={SpendConcentration}/>,
    <R key={k()} w={sim} path={r.job_koi_cfo} component={KOICashFlowOptimization}/>,
    <R key={k()} w={sim} path={r.job_koi_ss} component={KOISupplierSegmentation}/>,
    <R key={k()} w={sim} path={r.job_koi_ppv} component={KOIPurchasePriceVariance}/>,
    <R key={k()} w={sim} path={r.job_koi_kdf} component={KoiKeyDataFacts}/>,
    <R key={k()} w={sim} path={r.job_koi_sh} component={KoiSupplierHierarchy}/>,

    // New user flow
    <R key={k()} w={sim} path={r.job_manage} component={ManageDataPage}/>,
    <R key={k()} w={sim} path={r.job_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={sim} path={r.job_report} component={ReportingPage}/>,

    <R key={k()} w={duo} path={r.job_ai_cat_welcome} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_busy} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_review} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_result} component={CategorizationPage}/>,


    <R key={k()} w={duo} path={r.job_synergy_v1_suppliers} component={SynergySuppliersPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_categories} component={SynergyCategoriesPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_dashboard} component={SynergyDashboardPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_opportunities} component={OpportunitiesTrello}/>,

    <R key={k()} w={sim} path={r.job_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={sim} path={r.job_taxonomy_builder} component={TaxonomyEditorPage}/>,

    <R key={k()} w={duo} path={r.synergy_v2_dashboard} component={AtkDashBoard}/>,
    <R key={k()} w={duo} path={r.synergy_v2_review} component={AiCategorizationPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_review_result} component={AiCategorizationPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_taxonomy_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_taxonomy_mapper} component={TaxonomyMapperPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={noD} path={r.synergy_v2_data} component={ManageDataPage}/>,
    <R key={k()} w={noD} path={r.synergy_v2} component={ManageDataPage}/>,

    <R key={k()} w={noD} path={r.approval_cat_detail} component={ApprovalCategorizationPage}/>,
    <R key={k()} w={noD} path={r.approval_tax_detail} component={ApprovalTaxonomyPage}/>,
    <R key={k()} w={noD} path={r.approval} component={ApprovalListPage}/>,

    // Catch for incomplete synergy views (must be at the end)
    <R key={k()} w={noD} path={r.job_synergy_v1_1} component={SynergySelectionPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_2} component={SynergySelectionPage}/>,
    <R key={k()} w={noD} path={r.job_synergy_v1_0} component={SynergySelectionPage}/>,

    // Looker Preview Page
    <R key={k()} w={MainComponent} path={r.looker_preview_id} component={LookerPreviewPage}/>,
    <R key={k()} w={MainComponent} path={r.looker_preview} component={LookerPreviewPage}/>,

    // Authentication
    <Route key={k()} exact path={r.login} component={LoginPage}/>,

    // Last catch is a 404
    <WrappedRoute key={k()} w={MainComponent} component={NotFound}/>,
]
