import './TaxonomyEditor.scss'
import * as d3 from "d3";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useStores} from "../../../stores";
import {IS_TE_DEVELOPMENT_MODE} from "../../../pages/taxonomy-editor/TaxonomyEditorPage";
import {DEFAULT_TAXONOMY_EDITOR_OPTIONS, TaxonomyEditorOptions} from "./TaxonomyEditorOptions";
import {TaxonomyEditorController} from "./TaxonomyEditorController";
import {observer} from "mobx-react-lite";


type Props = {
    /**
     * Initial input data
     */
    options?: Partial<TaxonomyEditorOptions>,
};
export const TaxonomyEditor: React.FC<Props> = observer(({options}) => {
    const {taxonomyEditorStore, taxonomyManagerStore} = useStores()
    const svgRef = useRef<SVGSVGElement>(null)


    const [controller, setController] = useState<TaxonomyEditorController | undefined>(undefined)

    const o = useMemo<TaxonomyEditorOptions>(() => ({
        ...DEFAULT_TAXONOMY_EDITOR_OPTIONS,
        ...options,
    }), [options])
    // if (HARDCODE_HACK_VION) {
    //     const zoom = 1.8
    //     o.width = 1920 / zoom
    //     o.height = 880 / zoom
    //     o.margin.top = 50
    //     o.margin.bottom = 10
    //     o.margin.left = 100 + o.width / 6
    //     o.margin.right = 100 + o.width / 6
    // }
    // if (HARDCODE_HACK_FRANKE) {
    //     o.margin.left = 180
    //     o.margin.right = 80
    // }

    const taxonomy = taxonomyManagerStore.taxonomy;
    useEffect(() => {
        if (!taxonomy) {
            // console.log('Updating the controller: SKIP')
            return
        }
        if (!controller && svgRef.current) {
            // console.log('Updating the controller: CREATE')
            const svg = d3.select(svgRef.current as SVGElement);
            const controller = new TaxonomyEditorController(svg, taxonomy, o, taxonomyEditorStore);
            setController(controller)
        } else if (controller) {
            // console.log('Updating the controller: UPDATE')
            controller.reDrawNewData(taxonomy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxonomy, controller])

    let className = 'taxonomy-editor-viz'
    if (IS_TE_DEVELOPMENT_MODE) {
        className += ' debug'
    }
    if (taxonomyManagerStore.historyState !== 'ready') {
        // TODO-DISCUSS: We can visually show then we are saving in the background, do we still want to add a button?
        //  >>> 1/2h DISCUSS
        className += ' loading'
    }
    if (taxonomyManagerStore.historyState === 'updating_history') {
        className += ' blocked'
    }

    return <svg
        className={className}
        ref={svgRef}
        viewBox={`0 0 ${o.width} ${o.height + 100}`}
    />;
})
